(function () {
    'use strict';

    angular.module('eduAction')
        .controller('main-controller', function ($scope, $rootScope, $state, $transitions, $localstorage, $timeout) {

            console.log($rootScope.currentUser)

            $rootScope.go_usa = function () {
                $state.go('home.usa')
            };
            $rootScope.go_uk = function () {
                $state.go('home.uk')
            };
            $rootScope.go_my = function () {
                $state.go('home.my')
            };
            $rootScope.go_sg = function () {
                $state.go('home.sg')
            };
            $rootScope.go_au = function () {
                $state.go('home.au')
            };
            $rootScope.go_kr = function () {
                $state.go('home.kr')
            };

            $(".card-wrapper1").slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000,
                responsive: [
                    {
                        breakpoint: 1023,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1
                        }
                    }]
            });

        
        $timeout(function(){
            $(".reviews-wrapper").slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 2000,
                responsive: [
                    {
                        breakpoint: 1023,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1
                        }
                    },
                    {
                        breakpoint: 300,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1
                        }
                    }]
            });
        }, 1000);
            


            $('.read-more').click(function(){
                if($(this).parent().parent().find('p').css("height") == '95px'){
                    $(this).parent().parent().find('p').animate({height:"100%"}, "slow");
                    $(this).text('Read Less');
                } else {
                    $(this).parent().parent().find('p').animate({height:"95px"}, "slow");
                    $(this).text('Read More')
                }
                console.log($(this))
              
             
            })


        });

}());