(function () {
   'use strict';

   angular.module('eduAction')
      .controller('formUploads', function ($scope, $rootScope, $state, $transitions, $http, toaster, $timeout) {

         $scope.submitting_uploads = false;

         $scope.submit_uploads_form = function () {
            if ($rootScope.currentUser.userFiles.length >= 5) {
               $scope.submitting_uploads = true;
               console.log($("#transcripts").files)
               var dataUpdate = new FormData();
               dataUpdate.append("user_id", $rootScope.currentUser.id)
               //dataUpdate.user_id = $rootScope.currentUser.id;
               //	dataUpdate.file = [];
               //dataUpdate.file.push(transcriptUpload);
               /*for (var key of dataUpdate.entries()) {
               	console.log(key[0] + ', ' + key[1]);
               }*/


               var files = $("input[type='file']");
               $.each($("input[type='file']"), function (i, obj) {
                  $.each(obj.files, function (j, file) {
                     dataUpdate.append('file[]', file);
                     dataUpdate.append('titles[]', $(obj).attr("id"));
                  })
               });

               var json_arr = JSON.stringify($scope.uploadsAll);

               /*for (var key in dataUpdate.entries()) {
               	console.log(key);
               };*/

               //dataUpdate.append("file[]", json_arr);

               console.log(dataUpdate);

               $.ajax({
                  url: 'http://api.edu-action.com/api/user/upload-files',
                  cache: false,
                  contentType: false,
                  processData: false,
                  data: dataUpdate,
                  type: 'POST',
                  success: function (response) {
                     //console.log('response == >', response);
                     console.log(response, "SUCCESSSSSSS");
                     $scope.updateCurrentUser();
                     $scope.submitting_uploads = false;
                     $timeout(function () {
                        $rootScope.calculateCircle();
                        $state.go('home.application.college-status')
                     }, 1000);

                    toaster.pop('success', "Success", "Your informsadasdation Updated successfully");
                     setTimeout(function(){
                        $(".toast, .toast-top-right").click();
                        console.log('Please, have mercy')
                     },1500)
                  },
                  error: function (error, a, b, c) {
                     console.log(error, a, b, c);
                     $scope.submitting_uploads = false;
                  }
               });
            }
         };




         /*$http({
         	method: 'POST',
         	url: 'http://api.edu-action.com/api/user/upload-files',
         	data: dataUpdate,
         	//withCredentials: true,
         	headers: {
         		'Content-Type': 'application/x-www-form-urlencoded'
         	}
         }).then(function (data) {
         	console.log(" UPLOADED ", data);
         	
         	//	$state.go('home.application.form-objectives');
         }, function (err, status, config, headers) {
         	console.log(err, status, config, headers);
         })*/

         /*upload documents*/

         $scope.documents = [
            {
               model: "uploadTranscript",
               id: "transcript"
				}, {
               model: "financialUpload",
               id: "financial"
				}, {
               model: "resumeUpload",
               id: "resume"
				}, {
               model: "essayUpload",
               id: "essay"
				}, {
               model: "toeflUpload",
               id: "toefl"
				}, {
               model: "ieltsUpload",
               id: "ielts"
				}, {
               model: "passportUpload",
               id: "passport"
				}, {
               model: "otherUpload",
               id: "other"
				}
			];

         $scope.uploadsAll = [];


         $scope.openFile = function (what, event) {
            $scope.alreadyThere = false;
            console.log(what);
            $rootScope.currentUser.userFiles.forEach(function (p) {
               if (what == p.title) {
                  $scope.alreadyThere = true;
               }

            })
            if ($scope.alreadyThere) {
               toaster.pop('error', 'error', "You already uploaded " + what);
                setTimeout(function(){
                        $(".toast, .toast-top-right").click();
                        console.log('Please, have mercy')
                     },1500);
            } else {
               $("#" + what).trigger('click');
            }



            //$scope.idElement = null;
         };

         $scope.pushToArray = function (item) {
            //item.whatt = $(this)
            console.log(item)
           // var date = new Date();
            /*item.uploadedDate = date;
            $rootScope.currentUser.userFiles.push({
               name: item.name,
               title: item.id
            });*/
            $scope.submitting_uploads = true;
               var dataUpdate = new FormData();
               dataUpdate.append("user_id", $rootScope.currentUser.id)
               //dataUpdate.user_id = $rootScope.currentUser.id;
               //	dataUpdate.file = [];
               //dataUpdate.file.push(transcriptUpload);
               /*for (var key of dataUpdate.entries()) {
               	console.log(key[0] + ', ' + key[1]);
               }*/


               var files = $("input[type='file']");
               $.each($("input[type='file']"), function (i, obj) {
                  $.each(obj.files, function (j, file) {
                     dataUpdate.append('file[]', file);
                     dataUpdate.append('titles[]', $(obj).attr("id"));
                  })
               });

               var json_arr = JSON.stringify($scope.uploadsAll);

               /*for (var key in dataUpdate.entries()) {
               	console.log(key);
               };*/

               //dataUpdate.append("file[]", json_arr);

               console.log(dataUpdate);

               $.ajax({
                  url: 'http://api.edu-action.com/api/user/upload-files',
                  cache: false,
                  contentType: false,
                  processData: false,
                  data: dataUpdate,
                  type: 'POST',
                  success: function (response) {
                     //console.log('response == >', response);
                     console.log(response, "SUCCESSSSSSS");
                     $scope.updateCurrentUser();
                     $scope.submitting_uploads = false;
                     $timeout(function () {
                        $rootScope.calculateCircle();
                        $('#uploadsForm')[0].reset();

                       // $state.go('home.application.college-status')
                     }, 1000);

                     toaster.pop('success', "Success", "Your information Updated successfully");
                  },
                  error: function (error, a, b, c) {
                     console.log(error, a, b, c);
                     $scope.submitting_uploads = false;
                  }
               });
         };

         $scope.removeUpload = function (item) {
            var deleteItem = $.param({
               id: item.id
            });
            $http({
               method: 'POST',
               url: 'http://api.edu-action.com/api/user/remove-file',
               data: deleteItem,
               //withCredentials: true,
               headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
               }
            }).then(function (data) {
               toaster.pop('success', "Success", "File Deleted")
               $scope.updateCurrentUser();
               $rootScope.calculateCircle();
               //	$state.go('home.application.form-objectives');
            }, function (err, status, config, headers) {
               console.log(err, status, config, headers);
            })
         }

      });

}());