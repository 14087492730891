(function () {
    'use strict';

    angular.module('eduAction')
        .controller('home', function ($scope, $rootScope, $state, $transitions, $localstorage, $window, $http, $timeout, toaster) {

            $scope.options = {
                minDate: new Date(),
                showWeeks: true
            };

            $scope.isAuth = function () {
                if ($localstorage.get('access_token') != undefined) {
                    return true
                } else {
                    return false
                }
            }; 
       
       
       $scope.callContactModal = function(){
         $('#myModal').modal()
       }
       
       
        
        $scope.toggleDropdownNav = function(){
            $('.navigation-bar').fadeToggle();
        }

            if ($rootScope.currentUser == undefined && $localstorage.getObject('currentUser') != undefined) {
                $rootScope.currentUser = $localstorage.getObject('currentUser');
            }

            $scope.updateCurrentUser = function () {
                if ($scope.isAuth()) {
                    $http.get('http://api.edu-action.com/api/user/profile?id=' + $rootScope.currentUser.id)
                        .then(function (resp) {
                            //	console.log(resp.data);
                            $rootScope.currentUser = resp.data;
                            $rootScope.calculateCircle();
                            $localstorage.setObject('currentUser', $rootScope.currentUser);
                        }, function (err) {
                            console.log(err)
                        });
                } else {
                    console.log("Not a user")
                }

            };

            $scope.updateCurrentUser()


            console.log($scope.isAuth())

            $rootScope.signOut = function () {
                localStorage.removeItem('access_token');
                localStorage.removeItem('currentUser');
                $rootScope.currentUser = {};
                $window.location.reload();
            }

            $rootScope.go_apply_college = function () {
                if ($scope.isAuth()) {
                    $state.go("home.application.overall")
                } else {
                    $rootScope.go_signup();
                }
            }

            $rootScope.go_signup = function () {
                $state.go('login', {
                    isLogin: true
                });
            }
            $rootScope.go_login = function () {
                $state.go('login', {
                    isLogin: false
                });
            }

            $rootScope.go_application = function () {
                $state.go('home.application.overall');
            }

            $scope.sendEmail = {};
            $scope.sendingMessage = false;
            $scope.send_message = function (valid) {
                if(valid){
                    $scope.sendingMessage = true;
                   $scope.sendEmail.send_to = 'saidbek137@gmail.com'
                   $scope.sendEmail.send_from = 'contacts@edu-action.com'

                var new_message = $.param($scope.sendEmail);
                $http.post('http://api.edu-action.com/api/email/send', $scope.sendEmail)
                    .then(function (data) {
                    console.log(data)
                        $scope.sendingMessage = false;
                        $scope.sendEmail = {};
                        toaster.pop("success", "Email sent successfully")
                    }, function (err) {
                        $scope.signing_up = false;
                        console.log(err);
                    });
                } else {
                    toaster.pop("error", "Please fill all the required fields")
                }
                    
            }

            $transitions.onSuccess({}, function () {
                console.log($state.current.name);
                $(window).scrollTop(0);
                return false;
                if ($state.current.name == 'home') {
                    $state.go('home.main');
                }
            });

            $scope.getting_home_countries = true;
            $http.get('http://api.edu-action.com/api/category/countries')
                .then(function (resp) {
                    $scope.getting_home_countries = false;
                    $scope.countries_of_study = resp.data.data;
                    console.log('Countrues ', $scope.countries_of_study);
                }, function (err) {
                    console.log(err);
                    $scope.getting_home_countries = false;
                });


            $scope.getting_home_majors = true;
            $http.get('http://api.edu-action.com/api/category/directions')
                .then(function (resp) {
                    $scope.majors_of_study = resp.data.data;
                    $scope.getting_home_majors = false;
                    console.log('Majors ', $scope.majors_of_study);
                }, function (err) {
                    console.log(err);
                    $scope.getting_home_majors = false;
                });
            $http.get('http://api.edu-action.com/api/university')
                .then(function (resp) {
                    $scope.universities_of_study = resp.data.data;

                    console.log('universities_of_study ', $scope.universities_of_study);
                }, function (err) {
                    console.log(err)
                });

            $scope.stripAddr = function (address) {
                return address.replace("&amp;", "&");
            }
        });

}());