(function () {
      'use strict';

      angular.module('eduAction')
            .controller('formPersonal', function ($scope, $rootScope, $state, $transitions, $http, toaster, $filter, $timeout) {

                  $scope.submitting_personal = false;
                  $scope.issuedDate = {};
                  $scope.expireDate = {};
                  console.log("asdasdasdadwdll");

                  $scope.personal.languages = [];


                  $scope.applicationFormSubmitted = false;
                  $scope.submit_personal_form = function (valid, languages) {
                        if (valid) {
                              var issued_date = $scope.issuedDate.month + " " + $scope.issuedDate.day + ", " + $scope.issuedDate.year;
                              var expire_date = $scope.expireDate.month + " " + $scope.expireDate.day + ", " + $scope.expireDate.year;
                              $scope.personal.passport_issued_date = issued_date
                              $scope.personal.passport_expiration_date = expire_date

                              $scope.personal.user_id = $rootScope.currentUser.id
                              $scope.submitting_personal = true;
                              languages.forEach(function (p) {
                                    $scope.personal.languages.push(p.id);
                              });

                              var personal_form = $.param($scope.personal);
                              $http({
                                    method: 'POST',
                                    url: 'https://api.edu-action.com/api/user/save-personal-info',
                                    data: personal_form,
                                    //withCredentials: true,
                                    headers: {
                                          'Content-Type': 'application/x-www-form-urlencoded'
                                    }
                              }).then(function (data) {
                                    console.log(data);
                                    toaster.pop('success', "Your information Updated successfully");
                                    console.log(data);
                                    $scope.updateCurrentUser();
                                    $timeout(function () {
                                          $rootScope.calculateCircle();
                                          $state.go('home.application.form-contact');
                                    }, 1000);
                                    $scope.submitting_personal = false;
                              }, function (err, status, config, headers) {
                                    console.log(err, status, config, headers);
                                    $scope.submitting_personal = false;
                              })

                        } else {
                              console.log("INVALID")
                              $scope.applicationFormSubmitted = true;
                        }
                  }

            });

}());