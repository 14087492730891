(function () {
	'use strict';

	angular.module('eduAction')
		.controller('application', function ($scope, $rootScope, $state, $timeout, application_datas, $localstorage, $http, toaster) {

			console.log($rootScope.currentUser);
        if(jQuery.isEmptyObject($rootScope.currentUser)){
           // console.log('true')
            $state.go('home.main');
        } else {
            console.log('false')
        }
			$scope.countryList = application_datas.countries;
			$scope.degreeApplying = application_datas.degreeApplying;
			$scope.currentDegree = application_datas.currentDegree;
			var newData = application_datas.spokenLanguages;
			newData.then(function(resp){
				//console.log(resp);
				$scope.spokenLanguages = resp.data.data;
				//console.log($scope.spokenLanguages);
				
			}, function(err){
				console.log(err);
			});
		
			$scope.years = application_datas.years;
			$scope.genders = application_datas.genders;
			$scope.booleans = application_datas.booleans;

			$scope.drawCircle = function (val, color) {
				var progressBarOptions = {
					startAngle: -1.55,
					size: 70,
					value: val,
					fill: {
						color: color
					}
				}

				$('.circle').circleProgress(progressBarOptions).on('circle-animation-progress', function (event, progress, stepValue) {
					if(stepValue == 1) {
						$(this).find('strong').text('100%');	
					} else {
						$(this).find('strong').text(String(stepValue.toFixed(2)).substr(2) + '%');	
					}
				});
			};
       
       
       
       $scope.submitting_personal = false;
         $scope.personal = {};
         console.log($rootScope.currentUser.info)
        if ($rootScope.currentUser.info != null && $rootScope.currentUser.info.citizen != null && $rootScope.currentUser.info.passport_issued_date != null) {
            $scope.personal = $rootScope.currentUser.info
            //$scope.personal.passport_issued_date = new Date($scope.personal.passport_issued_date)
           // $scope.personal.passport_expiration_date = new Date($scope.personal.passport_expiration_date);
            $scope.languages = [];
          // conso
           
           $scope.issuedDate = {};
           $scope.expireDate = {};
           $scope.start1 = {};
           var iss = $scope.personal.passport_issued_date;
           var exp = $scope.personal.passport_expiration_date;
                $scope.issuedDate.day = iss.substring(0, iss.indexOf(',')).split(' ')[1];
                $scope.issuedDate.month = iss.substring(0, iss.indexOf(' '));
                $scope.issuedDate.year = iss.split(', ')[1];
           
           
                $scope.expireDate.day = exp.substring(0, exp.indexOf(',')).split(' ')[1];
                $scope.expireDate.month = exp.substring(0, exp.indexOf(' '));
                $scope.expireDate.year = exp.split(', ')[1];
           
           

            $rootScope.currentUser.languages.forEach(function (p) {
               $scope.languages.push(p.name);
            })

            /* console.log($rootScope.currentUser.languages);
             console.log($scope.languages);*/

            setTimeout(function () {
               $scope.languages.forEach(function (p) {
                  $scope.spokenLanguages.forEach(function (d) {
                     if (p == d.name) {
                        $.each($(".pre-selected-language span"), function (i, obj) {
                           if ($(obj).text() == d.name) {
                              $(obj).parent().trigger('click');

                           };
                        });
                     }
                  })
               })
               console.log($scope.items)
            }, 2000)

            console.log($scope.personal);
            /*;*/

         } else {
            $scope.personal = {};
            $scope.issuedDate = {};
            $scope.expireDate = {};
         }
       $scope.personal.languages = [];
       $scope.applicationFormSubmitted = false;
         $scope.submit_personal_form_app = function (valid, languages) {
            if (valid) {
               var issued_date = $scope.issuedDate.month + " " + $scope.issuedDate.day + ", " + $scope.issuedDate.year;
               var expire_date = $scope.expireDate.month + " " + $scope.expireDate.day + ", " + $scope.expireDate.year;
               $scope.personal.passport_issued_date = issued_date
               $scope.personal.passport_expiration_date = expire_date

               $scope.personal.user_id = $rootScope.currentUser.id
               $scope.submitting_personal = true;
               languages.forEach(function (p) {
                  $scope.personal.languages.push(p.id);
               });
            
            console.log($scope.personal);

               var personal_form = $.param($scope.personal);
               $http({
                  method: 'POST',
                  url: 'http://api.edu-action.com/api/user/save-personal-info',
                  data: personal_form,
                  //withCredentials: true,
                  headers: {
                     'Content-Type': 'application/x-www-form-urlencoded'
                  }
               }).then(function (data) {
                  console.log(data);
                  toaster.pop('success', "Your information Updated successfully");
                  console.log(data);
                  $scope.updateCurrentUser();
                  $timeout(function () {
                     $rootScope.calculateCircle();
                     $state.go('home.application.form-contact');
                  }, 1000);
                  $scope.submitting_personal = false;
               }, function (err, status, config, headers) {
                  console.log(err, status, config, headers);
                  $scope.submitting_personal = false;
               })

           } else {
               console.log("INVALID")
               $scope.applicationFormSubmitted = true;
            }
         }


			$rootScope.calculateCircle = function () {
                //console.log($rootScope.currentUser)
				var array_circle = [],
					sponsor_val, objective_val, contact_val, info_val, files_val;
				if ($rootScope.currentUser.sponsor != null) {
					sponsor_val = .20
				} else {
					sponsor_val = 0
				}
				if ($rootScope.currentUser.objective != null) {
					objective_val = .20
				} else {
					objective_val = 0
				}
				if ($rootScope.currentUser.contact != null) {
					contact_val = .20
				} else {
					contact_val = 0
				}
				if ($rootScope.currentUser.info != null && $rootScope.currentUser.info.citizen != null && $rootScope.currentUser.info.passport_issued_date != null) {
					info_val = .20
				} else {
					info_val = 0
				}
				if ($rootScope.currentUser.userFiles && $rootScope.currentUser.userFiles.length > 3) {
					files_val = .20
				} else {
					files_val = 0
				}
				
				var color;
               // console.log($rootScope.currentUser.userFiles)
				var final = sponsor_val + objective_val + contact_val + info_val + files_val;
				// console.log(sponsor_val, objective_val, contact_val, info_val, files_val);
				// console.log("final", final);
                $scope.final_process = final;
				if(final == '1'){
					color = '#38ff48'
				} else {
					color = '#FB6B1D'
				}
				$scope.drawCircle(final.toString(), color)
			};

			$rootScope.calculateCircle();
       
       $scope.days = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
       console.log("$scope.days ==== >>>", $scope.days);
    
    $scope.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    $scope.yearss = ["2001", "2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020"];
       
       $scope.yearsss = ["2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2030", "2031", "2032"];
       
         $scope.countries = [
            {
               "name": "Afghanistan",
               "code": "AF"
				},
            {
               "name": "Åland Islands",
               "code": "AX"
				},
            {
               "name": "Albania",
               "code": "AL"
				},
            {
               "name": "Algeria",
               "code": "DZ"
				},
            {
               "name": "American Samoa",
               "code": "AS"
				},
            {
               "name": "AndorrA",
               "code": "AD"
				},
            {
               "name": "Angola",
               "code": "AO"
				},
            {
               "name": "Anguilla",
               "code": "AI"
				},
            {
               "name": "Antarctica",
               "code": "AQ"
				},
            {
               "name": "Antigua and Barbuda",
               "code": "AG"
				},
            {
               "name": "Argentina",
               "code": "AR"
				},
            {
               "name": "Armenia",
               "code": "AM"
				},
            {
               "name": "Aruba",
               "code": "AW"
				},
            {
               "name": "Australia",
               "code": "AU"
				},
            {
               "name": "Austria",
               "code": "AT"
				},
            {
               "name": "Azerbaijan",
               "code": "AZ"
				},
            {
               "name": "Bahamas",
               "code": "BS"
				},
            {
               "name": "Bahrain",
               "code": "BH"
				},
            {
               "name": "Bangladesh",
               "code": "BD"
				},
            {
               "name": "Barbados",
               "code": "BB"
				},
            {
               "name": "Belarus",
               "code": "BY"
				},
            {
               "name": "Belgium",
               "code": "BE"
				},
            {
               "name": "Belize",
               "code": "BZ"
				},
            {
               "name": "Benin",
               "code": "BJ"
				},
            {
               "name": "Bermuda",
               "code": "BM"
				},
            {
               "name": "Bhutan",
               "code": "BT"
				},
            {
               "name": "Bolivia",
               "code": "BO"
				},
            {
               "name": "Bosnia and Herzegovina",
               "code": "BA"
				},
            {
               "name": "Botswana",
               "code": "BW"
				},
            {
               "name": "Bouvet Island",
               "code": "BV"
				},
            {
               "name": "Brazil",
               "code": "BR"
				},
            {
               "name": "British Indian Ocean Territory",
               "code": "IO"
				},
            {
               "name": "Brunei Darussalam",
               "code": "BN"
				},
            {
               "name": "Bulgaria",
               "code": "BG"
				},
            {
               "name": "Burkina Faso",
               "code": "BF"
				},
            {
               "name": "Burundi",
               "code": "BI"
				},
            {
               "name": "Cambodia",
               "code": "KH"
				},
            {
               "name": "Cameroon",
               "code": "CM"
				},
            {
               "name": "Canada",
               "code": "CA"
				},
            {
               "name": "Cape Verde",
               "code": "CV"
				},
            {
               "name": "Cayman Islands",
               "code": "KY"
				},
            {
               "name": "Central African Republic",
               "code": "CF"
				},
            {
               "name": "Chad",
               "code": "TD"
				},
            {
               "name": "Chile",
               "code": "CL"
				},
            {
               "name": "China",
               "code": "CN"
				},
            {
               "name": "Christmas Island",
               "code": "CX"
				},
            {
               "name": "Cocos (Keeling) Islands",
               "code": "CC"
				},
            {
               "name": "Colombia",
               "code": "CO"
				},
            {
               "name": "Comoros",
               "code": "KM"
				},
            {
               "name": "Congo",
               "code": "CG"
				},
            {
               "name": "Congo, The Democratic Republic of the",
               "code": "CD"
				},
            {
               "name": "Cook Islands",
               "code": "CK"
				},
            {
               "name": "Costa Rica",
               "code": "CR"
				},
            {
               "name": "Cote D'Ivoire",
               "code": "CI"
				},
            {
               "name": "Croatia",
               "code": "HR"
				},
            {
               "name": "Cuba",
               "code": "CU"
				},
            {
               "name": "Cyprus",
               "code": "CY"
				},
            {
               "name": "Czech Republic",
               "code": "CZ"
				},
            {
               "name": "Denmark",
               "code": "DK"
				},
            {
               "name": "Djibouti",
               "code": "DJ"
				},
            {
               "name": "Dominica",
               "code": "DM"
				},
            {
               "name": "Dominican Republic",
               "code": "DO"
				},
            {
               "name": "Ecuador",
               "code": "EC"
				},
            {
               "name": "Egypt",
               "code": "EG"
				},
            {
               "name": "El Salvador",
               "code": "SV"
				},
            {
               "name": "Equatorial Guinea",
               "code": "GQ"
				},
            {
               "name": "Eritrea",
               "code": "ER"
				},
            {
               "name": "Estonia",
               "code": "EE"
				},
            {
               "name": "Ethiopia",
               "code": "ET"
				},
            {
               "name": "Falkland Islands (Malvinas)",
               "code": "FK"
				},
            {
               "name": "Faroe Islands",
               "code": "FO"
				},
            {
               "name": "Fiji",
               "code": "FJ"
				},
            {
               "name": "Finland",
               "code": "FI"
				},
            {
               "name": "France",
               "code": "FR"
				},
            {
               "name": "French Guiana",
               "code": "GF"
				},
            {
               "name": "French Polynesia",
               "code": "PF"
				},
            {
               "name": "French Southern Territories",
               "code": "TF"
				},
            {
               "name": "Gabon",
               "code": "GA"
				},
            {
               "name": "Gambia",
               "code": "GM"
				},
            {
               "name": "Georgia",
               "code": "GE"
				},
            {
               "name": "Germany",
               "code": "DE"
				},
            {
               "name": "Ghana",
               "code": "GH"
				},
            {
               "name": "Gibraltar",
               "code": "GI"
				},
            {
               "name": "Greece",
               "code": "GR"
				},
            {
               "name": "Greenland",
               "code": "GL"
				},
            {
               "name": "Grenada",
               "code": "GD"
				},
            {
               "name": "Guadeloupe",
               "code": "GP"
				},
            {
               "name": "Guam",
               "code": "GU"
				},
            {
               "name": "Guatemala",
               "code": "GT"
				},
            {
               "name": "Guernsey",
               "code": "GG"
				},
            {
               "name": "Guinea",
               "code": "GN"
				},
            {
               "name": "Guinea-Bissau",
               "code": "GW"
				},
            {
               "name": "Guyana",
               "code": "GY"
				},
            {
               "name": "Haiti",
               "code": "HT"
				},
            {
               "name": "Heard Island and Mcdonald Islands",
               "code": "HM"
				},
            {
               "name": "Holy See (Vatican City State)",
               "code": "VA"
				},
            {
               "name": "Honduras",
               "code": "HN"
				},
            {
               "name": "Hong Kong",
               "code": "HK"
				},
            {
               "name": "Hungary",
               "code": "HU"
				},
            {
               "name": "Iceland",
               "code": "IS"
				},
            {
               "name": "India",
               "code": "IN"
				},
            {
               "name": "Indonesia",
               "code": "ID"
				},
            {
               "name": "Iran, Islamic Republic Of",
               "code": "IR"
				},
            {
               "name": "Iraq",
               "code": "IQ"
				},
            {
               "name": "Ireland",
               "code": "IE"
				},
            {
               "name": "Isle of Man",
               "code": "IM"
				},
            {
               "name": "Israel",
               "code": "IL"
				},
            {
               "name": "Italy",
               "code": "IT"
				},
            {
               "name": "Jamaica",
               "code": "JM"
				},
            {
               "name": "Japan",
               "code": "JP"
				},
            {
               "name": "Jersey",
               "code": "JE"
				},
            {
               "name": "Jordan",
               "code": "JO"
				},
            {
               "name": "Kazakhstan",
               "code": "KZ"
				},
            {
               "name": "Kenya",
               "code": "KE"
				},
            {
               "name": "Kiribati",
               "code": "KI"
				},
            {
               "name": "Korea, Democratic People'S Republic of",
               "code": "KP"
				},
            {
               "name": "Korea, Republic of",
               "code": "KR"
				},
            {
               "name": "Kuwait",
               "code": "KW"
				},
            {
               "name": "Kyrgyzstan",
               "code": "KG"
				},
            {
               "name": "Lao PeopleS Democratic Republic",
               "code": "LA"
				},
            {
               "name": "Latvia",
               "code": "LV"
				},
            {
               "name": "Lebanon",
               "code": "LB"
				},
            {
               "name": "Lesotho",
               "code": "LS"
				},
            {
               "name": "Liberia",
               "code": "LR"
				},
            {
               "name": "Libyan Arab Jamahiriya",
               "code": "LY"
				},
            {
               "name": "Liechtenstein",
               "code": "LI"
				},
            {
               "name": "Lithuania",
               "code": "LT"
				},
            {
               "name": "Luxembourg",
               "code": "LU"
				},
            {
               "name": "Macao",
               "code": "MO"
				},
            {
               "name": "Macedonia, The Former Yugoslav Republic of",
               "code": "MK"
				},
            {
               "name": "Madagascar",
               "code": "MG"
				},
            {
               "name": "Malawi",
               "code": "MW"
				},
            {
               "name": "Malaysia",
               "code": "MY"
				},
            {
               "name": "Maldives",
               "code": "MV"
				},
            {
               "name": "Mali",
               "code": "ML"
				},
            {
               "name": "Malta",
               "code": "MT"
				},
            {
               "name": "Marshall Islands",
               "code": "MH"
				},
            {
               "name": "Martinique",
               "code": "MQ"
				},
            {
               "name": "Mauritania",
               "code": "MR"
				},
            {
               "name": "Mauritius",
               "code": "MU"
				},
            {
               "name": "Mayotte",
               "code": "YT"
				},
            {
               "name": "Mexico",
               "code": "MX"
				},
            {
               "name": "Micronesia, Federated States of",
               "code": "FM"
				},
            {
               "name": "Moldova, Republic of",
               "code": "MD"
				},
            {
               "name": "Monaco",
               "code": "MC"
				},
            {
               "name": "Mongolia",
               "code": "MN"
				},
            {
               "name": "Montserrat",
               "code": "MS"
				},
            {
               "name": "Morocco",
               "code": "MA"
				},
            {
               "name": "Mozambique",
               "code": "MZ"
				},
            {
               "name": "Myanmar",
               "code": "MM"
				},
            {
               "name": "Namibia",
               "code": "NA"
				},
            {
               "name": "Nauru",
               "code": "NR"
				},
            {
               "name": "Nepal",
               "code": "NP"
				},
            {
               "name": "Netherlands",
               "code": "NL"
				},
            {
               "name": "Netherlands Antilles",
               "code": "AN"
				},
            {
               "name": "New Caledonia",
               "code": "NC"
				},
            {
               "name": "New Zealand",
               "code": "NZ"
				},
            {
               "name": "Nicaragua",
               "code": "NI"
				},
            {
               "name": "Niger",
               "code": "NE"
				},
            {
               "name": "Nigeria",
               "code": "NG"
				},
            {
               "name": "Niue",
               "code": "NU"
				},
            {
               "name": "Norfolk Island",
               "code": "NF"
				},
            {
               "name": "Northern Mariana Islands",
               "code": "MP"
				},
            {
               "name": "Norway",
               "code": "NO"
				},
            {
               "name": "Oman",
               "code": "OM"
				},
            {
               "name": "Pakistan",
               "code": "PK"
				},
            {
               "name": "Palau",
               "code": "PW"
				},
            {
               "name": "Palestinian Territory, Occupied",
               "code": "PS"
				},
            {
               "name": "Panama",
               "code": "PA"
				},
            {
               "name": "Papua New Guinea",
               "code": "PG"
				},
            {
               "name": "Paraguay",
               "code": "PY"
				},
            {
               "name": "Peru",
               "code": "PE"
				},
            {
               "name": "Philippines",
               "code": "PH"
				},
            {
               "name": "Pitcairn",
               "code": "PN"
				},
            {
               "name": "Poland",
               "code": "PL"
				},
            {
               "name": "Portugal",
               "code": "PT"
				},
            {
               "name": "Puerto Rico",
               "code": "PR"
				},
            {
               "name": "Qatar",
               "code": "QA"
				},
            {
               "name": "Reunion",
               "code": "RE"
				},
            {
               "name": "Romania",
               "code": "RO"
				},
            {
               "name": "Russian Federation",
               "code": "RU"
				},
            {
               "name": "RWANDA",
               "code": "RW"
				},
            {
               "name": "Saint Helena",
               "code": "SH"
				},
            {
               "name": "Saint Kitts and Nevis",
               "code": "KN"
				},
            {
               "name": "Saint Lucia",
               "code": "LC"
				},
            {
               "name": "Saint Pierre and Miquelon",
               "code": "PM"
				},
            {
               "name": "Saint Vincent and the Grenadines",
               "code": "VC"
				},
            {
               "name": "Samoa",
               "code": "WS"
				},
            {
               "name": "San Marino",
               "code": "SM"
				},
            {
               "name": "Sao Tome and Principe",
               "code": "ST"
				},
            {
               "name": "Saudi Arabia",
               "code": "SA"
				},
            {
               "name": "Senegal",
               "code": "SN"
				},
            {
               "name": "Serbia and Montenegro",
               "code": "CS"
				},
            {
               "name": "Seychelles",
               "code": "SC"
				},
            {
               "name": "Sierra Leone",
               "code": "SL"
				},
            {
               "name": "Singapore",
               "code": "SG"
				},
            {
               "name": "Slovakia",
               "code": "SK"
				},
            {
               "name": "Slovenia",
               "code": "SI"
				},
            {
               "name": "Solomon Islands",
               "code": "SB"
				},
            {
               "name": "Somalia",
               "code": "SO"
				},
            {
               "name": "South Africa",
               "code": "ZA"
				},
            {
               "name": "South Georgia and the South Sandwich Islands",
               "code": "GS"
				},
            {
               "name": "Spain",
               "code": "ES"
				},
            {
               "name": "Sri Lanka",
               "code": "LK"
				},
            {
               "name": "Sudan",
               "code": "SD"
				},
            {
               "name": "Suriname",
               "code": "SR"
				},
            {
               "name": "Svalbard and Jan Mayen",
               "code": "SJ"
				},
            {
               "name": "Swaziland",
               "code": "SZ"
				},
            {
               "name": "Sweden",
               "code": "SE"
				},
            {
               "name": "Switzerland",
               "code": "CH"
				},
            {
               "name": "Syrian Arab Republic",
               "code": "SY"
				},
            {
               "name": "Taiwan, Province of China",
               "code": "TW"
				},
            {
               "name": "Tajikistan",
               "code": "TJ"
				},
            {
               "name": "Tanzania, United Republic of",
               "code": "TZ"
				},
            {
               "name": "Thailand",
               "code": "TH"
				},
            {
               "name": "Timor-Leste",
               "code": "TL"
				},
            {
               "name": "Togo",
               "code": "TG"
				},
            {
               "name": "Tokelau",
               "code": "TK"
				},
            {
               "name": "Tonga",
               "code": "TO"
				},
            {
               "name": "Trinidad and Tobago",
               "code": "TT"
				},
            {
               "name": "Tunisia",
               "code": "TN"
				},
            {
               "name": "Turkey",
               "code": "TR"
				},
            {
               "name": "Turkmenistan",
               "code": "TM"
				},
            {
               "name": "Turks and Caicos Islands",
               "code": "TC"
				},
            {
               "name": "Tuvalu",
               "code": "TV"
				},
            {
               "name": "Uganda",
               "code": "UG"
				},
            {
               "name": "Ukraine",
               "code": "UA"
				},
            {
               "name": "United Arab Emirates",
               "code": "AE"
				},
            {
               "name": "United Kingdom",
               "code": "GB"
				},
            {
               "name": "United States",
               "code": "US"
				},
            {
               "name": "United States Minor Outlying Islands",
               "code": "UM"
				},
            {
               "name": "Uruguay",
               "code": "UY"
				},
            {
               "name": "Uzbekistan",
               "code": "UZ"
				},
            {
               "name": "Vanuatu",
               "code": "VU"
				},
            {
               "name": "Venezuela",
               "code": "VE"
				},
            {
               "name": "Viet Nam",
               "code": "VN"
				},
            {
               "name": "Virgin Islands, British",
               "code": "VG"
				},
            {
               "name": "Virgin Islands, U.S.",
               "code": "VI"
				},
            {
               "name": "Wallis and Futuna",
               "code": "WF"
				},
            {
               "name": "Western Sahara",
               "code": "EH"
				},
            {
               "name": "Yemen",
               "code": "YE"
				},
            {
               "name": "Zambia",
               "code": "ZM"
				},
            {
               "name": "Zimbabwe",
               "code": "ZW"
				}]



			/*$http.get('http://api.edu-action.com/api/category/exams')
				.then(function (resp) {
				//	console.log('EXAMS ', resp);
				}, function (err) {
					console.log(err)
				});*/

		});
}());