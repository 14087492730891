(function () {
   'use strict';

   angular.module('eduAction')
      .controller('login', function ($scope, $rootScope, $state, $timeout, $http, $localstorage, toaster) {


         $scope.forgot = {};

         $scope.submitForgot = function () {
            console.log($scope.forgot);
            var forgotPass = $.param($scope.forgot);
            $http({
               method: 'POST',
               url: 'https://api.edu-action.com/api/user/send-email',
               data: forgotPass,
               headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
               }
            })
               .then(function (resp) {
                  console.log(resp);
                  if (resp.status == 200) {
                     toaster.pop("success", "Success", "Please check Your Email");
                     $("#forgot-section").fadeOut();
                     $("#recovery-section").fadeIn();
                  }

                  if (resp.status == 404) {
                     toaster.pop("error", "Error", "Email does not exist in out database");
                  }
               }, function (err) {
                  console.log(err);
                  toaster.pop("error", "Error", "Something went wrong, Please try again later")
               })
         };

         /*code:integer
new_password:string
repeat_password: string*/

         $scope.recoverPassword = function () {
            var recoverPass = $.param($scope.recover);
            $http({
               method: 'POST',
               url: 'https://api.edu-action.com/api/user/change-password',
               data: recoverPass,
               headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
               }
            })
               .then(function (resp) {
                  console.log(resp);
                  $timeout(function () {
                     $state.go('login', {
                        isLogin: true
                     });
                  }, 100);
               }, function (err) {
                  console.log(err);
                  toaster.pop("error", "Error", "You Entered Wrong Code")
               })

         }



         $scope.submitNewPassword = function () {
            console.log($scope.forgot);
            $http.post('https://api.edu-action.com/api/user/change-password', JSON.stringify())
               .then(function (resp) {
                  console.log(resp.data.data);
                  /*if(resp.data.data == 1){
                    $scope.email_error = "E-mail you entered is already taken";
                  } else {
                    $scope.email_error = "E-mail is available";
                  }*/
               }, function (err) {
                  console.log(err);
               })
         }
         $timeout(function () {
            if ($state.params.isLogin == false) {
               console.log("IT IS LOGIN");
               $scope.stage = 'stage1';
               $scope.direction = 1;
            }
         }, 100)

         $rootScope.is_login_page = true;
         $scope.form_submitted = false;


         $scope.signing_in = false;
         $scope.signing_up = false;

         $scope.register = {}
         $scope.register.name = '';
         $scope.register.lastname = '';
         $scope.register.email = '';
         $scope.register.password = '';
         $scope.register.birth_date = '';
         $scope.password2 = '';

         $scope.matchPassword = function (password2) {
            console.log($scope.register.password, ' == ', password2)
            if ($scope.register.password != password2) {
               /*console.log('Password does not match');*/
               $scope.pass_match = true;
            } else {
               /*console.log('password MATCHSSS')*/
               $scope.pass_match = false;
            }
         };

         $scope.matchPasswordRec = function (pass) {
            console.log($scope.recover.new_password, ' == ', pass)
            if ($scope.recover.new_password != pass) {
               /*console.log('Password does not match');*/
               $scope.rec_pass_match = true;
            } else {
               /*console.log('password MATCHSSS')*/
               $scope.rec_pass_match = false;
            }
         };
         $scope.email_error = '';
         $scope.checkEmail = function (valid) {
            if (valid) {
               $http.post('https://api.edu-action.com/api/user/check-email', JSON.stringify({
                  email: $scope.register.email
               }))
                  .then(function (resp) {
                     console.log(resp.data.data);
                     if (resp.data.data == 1) {
                        $scope.email_error = "E-mail you entered is already taken";
                     } else {
                        $scope.email_error = "E-mail is available";
                     }
                  }, function (err) {
                     console.log(err);
                  })
            } else {
               toaster.pop("error", "Not a Valid email address")
            }
         }

         $scope.birthDate = {};
         $scope.sign_up = function (valid) {
            if (valid) {
               console.log($scope.register);
               var date_of_birth = $scope.birthDate.month + " " + $scope.birthDate.day + ", " + $scope.birthDate.year;
               $scope.register.birth_date = date_of_birth;
               $scope.signing_up = true;
               var newUser = $.param($scope.register);

               console.log($scope.register)
               $http({
                  method: 'POST',
                  url: 'https://api.edu-action.com/api/user/sign-up',
                  data: $scope.register,
                  headers: {
                     'Content-Type': 'application/json'
                  }
               }).then(function (data) {
                  $rootScope.currentUser = data.data.data;
                  console.log(data);
                  console.log($scope.currentUser)
                  $localstorage.setObject('currentUser', $scope.currentUser);
                  $localstorage.set('access_token', $scope.currentUser.token);
                  $scope.signing_up = false;
                  $timeout(function () {
                     $state.go('home.main');
                  }, 100);
               }, function (err, a, b, c) {
                  $scope.signing_up = false;
                  console.log(err, a, b, c);
               });

               /*$http.post('https://api.edu-action.com/api/user/sign-up', newUser)
                */

            } else {
               $scope.form_submitted = true;
               console.log("Form NOT valid")
            }
         };

         $scope.popup_open = false;


         $scope.login = {};
         $scope.signin = function (valid) {
            if (valid) {
               $scope.signing_in = true;
               var xsrf = $.param($scope.login);
               $http({
                  method: 'POST',
                  url: 'https://api.edu-action.com/api/user/sign-in',
                  data: xsrf,
                  headers: {
                     'Content-Type': 'application/x-www-form-urlencoded'
                  }
               }).then(function (data) {
                  console.log(data);
                  $rootScope.currentUser = data.data.data;
                  console.log($scope.currentUser)
                  $localstorage.setObject('currentUser', $scope.currentUser);
                  $localstorage.set('access_token', $scope.currentUser.token);
                  $scope.signing_in = false;
                  $timeout(function () {
                     $state.go('home.main');
                  }, 100);
               }, function (err, status, config, headers) {
                  console.log(err, status, config, headers);
                  console.log(err.data.message);
                  $scope.signing_in = false;
                  //toaster.pop("danger", "Wrong login or password")
                  if (err.data.message == "Не верный логин и/или пароль") {
                     toaster.pop("error", "Error", "Wrong login or password")
                  }
               })

               /*console.log(valid, $scope.login)
               $http.post('https://api.edu-action.com/api/user/sign-in', $scope.login)
               		.then(function(data){
               		console.log(data);
               	}, function(err, status, config, headers){
               		console.log(err, status, config, headers);
               	})*/
            } else {
               console.log('not valid');
            }
         }

         $scope.formParams = {};
         $scope.stage = "";
         $scope.formValidation = false;
         $scope.toggleJSONView = false;
         $scope.toggleFormErrorsView = false;


         // Navigation functions
         $scope.next = function (stage) {
            $scope.direction = 1;
            $scope.stage = stage;
         };

         $scope.back = function (stage) {
            $scope.direction = 0;
            $scope.stage = stage;
         };



         $scope.reset = function () {
            // Clean up scope before destorying
            $scope.formParams = {};
            $scope.stage = "";
         }

         $scope.days = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];

         $scope.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
         $scope.years = ["1980", "1981", "1982", "1983", "1984", "1985", "1986", "1987", "1988", "1989", "1990", "1991", "1992", "1993", "1994", "1995", "1996", "1997", "1998", "1999", "2000", "2001", "2002", "2003", "2004", "2005", "2006"];


      });
}());