(function () {
	'use strict';

	angular.module('eduAction')
		.controller('collegeController', function ($scope, $rootScope, $state, $transitions, $http) {
		
		
        $scope.searchMajor = function(item){
            $rootScope.searchValue = item;
            $rootScope.searchTerm = 'major'
            $state.go('home.college-search-results');
        }
        
        $scope.searchCountry = function(item) {
            $rootScope.searchValue = item;
            $rootScope.searchTerm = 'country'
            $state.go('home.college-search-results');
        }
        
        $scope.advanced_search = function(){
            $rootScope.searchTerm = 'advanced'
            $rootScope.searchValue = $scope.search;
            $state.go('home.college-search-results');
        }
        
        $scope.filterCountry = function(item){
            console.log(item)
        }
		
	});
	
}());
