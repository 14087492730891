(function () {
    'use strict';

    angular.module('eduAction')
        .controller('formObjectives', function ($scope, $rootScope, $state, $transitions, $http, toaster, $timeout) {



            /* below lines to submit objective form. */
            if ($rootScope.currentUser.objective != null) {
                $scope.objective = $rootScope.currentUser.objective;
                $scope.sselected_colleges = $rootScope.currentUser.objective.colleges;
                //$scope.selected_major = $rootScope.currentUser.objective.majors[0]
                var selectedMajor = $rootScope.currentUser.objective.majors[0];
                var s = $rootScope.currentUser.objective.intendent_start_uni;
                console.log(selectedMajor);
                $scope.start1 = {};
                $scope.start1.term = s.substring(0, s.indexOf(','));
                $scope.start1.year = s.split(', ')[1];

            } else {
                $scope.start1 = {};
                $scope.objective = {};
                $scope.sselected_colleges = [];
            }







            setTimeout(function () {
                console.log(selectedMajor)
                $("#selected_major").val(selectedMajor).change();
                //console.log('$scope.sselected_colleges ', $scope.sselected_colleges)
                // console.log('$scope.universities_of_study ',$scope.universities_of_study)
                $scope.sselected_colleges.forEach(function (p) {
                    $scope.universities_of_study.forEach(function (d) {
                        //console.log(p, d)
                        if (p == d.name) {
                            $.each($(".pre-selected-language span"), function (i, obj) {
                                if ($(obj).text() == d.name) {
                                    $(obj).parent().trigger('click');

                                };
                            });
                        }
                    });
                })
            }, 2000);





            /*
            $scope.objective.majors_interested = [];
            $scope.objective.selected_colleges = [];
            */

            $scope.select_major = function (item) {
                console.log(item)
                $scope.objective.majors_interested = [];
                $scope.objective.majors_interested.push(item.id);
            };


            //console.log($scope.start1.year);

            $scope.submitting_objective = false;

            $scope.objective_submitted = false;

            $scope.submit_objective = function (valid, array) {
                if (valid) {
                    $scope.objective.colleges = [];
                    $scope.objective.intendent_start_uni = $scope.start1.term + ", " + $scope.start1.year;
                    $scope.objective.user_id = parseFloat($rootScope.currentUser.id);


                    console.log(array);
                    console.log($scope.objective.colleges);
                    array.forEach(function (p) {
                        console.log(p);
                        $scope.objective.colleges.push(p.id)
                    })


                    console.log($scope.objective)
                    $scope.submitting_objective = true;
                    var objective_form = $.param($scope.objective);
                    $http({
                        method: 'POST',
                        url: 'http://api.edu-action.com/api/user/save-objectives',
                        data: objective_form,
                        //withCredentials: true,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then(function (data) {
                        $scope.submitting_objective = false;
                        console.log('OBJECTIVEEEEEE', data);
                        $scope.updateCurrentUser();
                        toaster.pop('success', 'SUCCESS', 'Your information Updated successfully', 3000);
                        
                        //toaster.pop('success', "Your information Updated successfully");
                        $timeout(function () {
                            $rootScope.calculateCircle();
                          $state.go('home.application.form-sponsors')
                        }, 1000);
                        //	$state.go('home.application.form-objectives');
                    }, function (err, status, config, headers) {
                        $scope.submitting_objective = false;
                        console.log(err, status, config, headers);
                    })
                } else {
                    toaster.pop('error', "Please fill all required fields");
                    $scope.objective_submitted = true;
                }

            }
            /* === End of user objective files  ===  */
        });

}());