(function () {
  'use strict';

  angular.module('eduAction')
    .controller('collegeSearchController', function ($scope, $rootScope, $state, $transitions, $http, toaster) {

      console.log($rootScope.searchValue);
      if ($rootScope.searchValue == undefined) {
        $state.go('home.colleges')
      }
      $scope.showLoading = true;

      if ($rootScope.searchTerm == 'country') {
        $http.get('http://api.edu-action.com/api/university/search?by_country=' + $rootScope.searchValue)
          .then(function (resp) {
            $scope.search_results = resp.data.data;
            $scope.showLoading = false;
          }, function (err) {
            console.log(err)
          });

      } else if ($rootScope.searchTerm == 'major') {
        $http.get('http://api.edu-action.com/api/university?major_id=' + $rootScope.searchValue)
          .then(function (resp) {
            $scope.search_results = resp.data.data;
            $scope.showLoading = false;
          }, function (err) {
            console.log(err)
          });

      } else if ($rootScope.searchTerm == 'advanced') {
        $http.get('http://api.edu-action.com/api/university/search?university_id=' + $rootScope.searchValue.university_id.id + '&by_country=' + $rootScope.searchValue.by_country)
          .then(function (resp) {
            $scope.search_results = resp.data.data;
            $scope.showLoading = false;
          }, function (err) {
            console.log(err)
          });
      }

      $scope.contact_me = function (user) {
        console.log(user);
        var message = {};
        message.name = user.name;
        message.phone = user.phone;
        message.email = user.email;
        message.message = " - Interested country: " + user.country + "; - University: " + user.university + "; - Major: " + user.major + "; - IELTS: " + user.ielts;
        message.send_to = 'saidbek137@gmail.com'
        message.send_from = 'contacts@edu-action.com';

        $http.post('http://api.edu-action.com/api/email/send', message)
          .then(function (data) {
            console.log(data);
            $scope.contact = {};
            toaster.pop("success", "Email sent successfully")
          }, function (err) {
            console.log(err);
          });
      }
    });

}());